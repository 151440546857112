import { triadManager } from "@/services/triad/triad-manager";
import { getIdb, setIdb } from "@/indexed-db";
import { skillManager } from "@/services/skill/skill-manager";
import { notificationManager } from "@/services/utilities/notification-manager";
import { i18n } from "@/i18n";
import { defineStore } from "pinia";
import { useMainStore } from "@/store/main/main";
import { useQuestionnaireStore } from "@/store/questionnaire/questionnaire";
import { useTrainingCourseStore } from "@/store/training-course/training-course";
import { mainStore } from "@/main";

export const useTriadStore = defineStore({
  id: "Triad",
  state: () => {
    return {
      triad: {},
      triads: {},
      contract: {},
      skills: {},
      subjects: {},
      webLinks: {},
      timelines: {},
      pedagogicDocuments: {},
      generalDocuments: {},
      documentCategories: {},
      documents: {},
      notepads: {},
      triadLoaded: false,
      sessions: {},
      currentSession: {},
      currentSessionSkillReply: {},
    };
  },

  actions: {
    changeTriad(payload) {
      if (!payload.disableLoading) {
        useMainStore().setAppLoading(true);
      }
      useTrainingCourseStore().toggleTrainingCourse(payload.trainingCourseId);
      window.localStorage.setItem("studea-oldTriad", payload.triadId);
      window.localStorage.setItem(
        "studea-oldTrainingCourse",
        payload.trainingCourseId,
      );
      if (mainStore.isInternet) {
        return triadManager.loadOne(payload.triadId).then((triad) => {
          if (triad && typeof triad === "object") {
            this.initTriadDatas(triad);
          }
        });
      } else {
        return getIdb(parseInt(payload.triadId), "triad").then((triad) => {
          if (triad && typeof triad === "object") {
            this.initTriadDatas(JSON.parse(triad));
          }
        });
      }
    },

    initTriadDatas(triad) {
      this.triad = triad;
      this.skills =
        triad.trainingCourse && triad.trainingCourse.skillCategories
          ? triad.trainingCourse.skillCategories
          : {};
      this.subjects =
        triad.trainingCourse && triad.trainingCourse.subjects
          ? triad.trainingCourse.subjects
          : {};
      this.webLinks =
        triad.trainingCourse && triad.trainingCourse.webLinks
          ? triad.trainingCourse.webLinks
          : {};
      if (Object.keys(triad.contracts).length) {
        this.contract = triad.contracts.find(
          (contract) => contract.activeContract === true,
        );
      }
      this.timelines = triad.timelines;
      this.notepads = triad.notepads;
      this.sessions = triad.sessions;
      this.setDocuments(triad);
      useQuestionnaireStore().setQuestionnaireToDoAndProgress(triad);

      useTrainingCourseStore().setTrainingCourseInfos(triad);

      setTimeout(function () {
        useMainStore().setAppLoading(false);
      }, 500);
    },

    sendSkills(skills) {
      useMainStore().savingRequest();
      return skillManager.postSkills(skills).then((success) => {
        notificationManager.showNotification(
          "success",
          i18n.global.t("Skills saved successfully"),
        );
        useMainStore().savingSuccess();
        return success;
      });
    },

    fetchSkills() {
      return skillManager.getTriadSkills().then((skills) => {
        this.skills = skills.skillCategories;
        this.triad.lastSkillReply = skills.lastSkillReply;
        return skills;
      });
    },

    setDocuments(triad) {
      this.documents = triad.documents;
      const pedagogicDocuments = triad.documents.find(
        (item) => item.code === "pedagogic",
      );
      if (pedagogicDocuments) {
        this.pedagogicDocuments = pedagogicDocuments.documents;
      }

      const generalDocuments = triad.documents.find(
        (item) => item.code === "general",
      );
      if (generalDocuments) {
        this.generalDocuments = generalDocuments.documents;
      }

      const documentCategories = triad.documents.filter(
        (item) => !["pedagogic", "general"].includes(item.code),
      );
      if (documentCategories) {
        this.documentCategories = documentCategories;
      }
    },

    setTriad(triad) {
      setIdb(parseInt(triad.id), JSON.stringify(triad), "triad");
      this.triad = triad;
      this.triadLoaded = true;
    },

    setCurrentSession(currentSession) {
      this.currentSession = currentSession;
    },

    setCurrentSessionSkillReply(currentSessionSkillReply) {
      this.currentSessionSkillReply = currentSessionSkillReply;
    },

    init() {
      this.triad = {};
      this.triads = {};
    },
  },
});
